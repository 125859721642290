import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";
import GlobalContext from "./Context/Context";
import ScrollToTopButton from "./Components/ScrollToTop";

const HomeBusinessPage = React.lazy(() => import("./Pages/Home/Startup/Startup"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    });
  }, [location]);

  useEffect(() => {
    const handleExternalLink = (e) => {
      if (e.target.tagName === 'A' && e.target.getAttribute('href').endsWith('.html')) {
        e.preventDefault();
        window.location.href = e.target.getAttribute('href');
      }
    };

    window.addEventListener('click', handleExternalLink);

    return () => {
      window.removeEventListener('click', handleExternalLink);
    };
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
          <ScrollToTopButton />
          <AnimatePresence exitBeforeEnter>
            <Suspense fallback={<></>}>
              <Routes>
                <Route path="/" element={<HomeBusinessPage style={{ "--base-color": "#3452ff" }} />} />
                <Route
                  path="*"
                  element={<div>404 Not Found</div>}
                />
                {/* Add more routes if needed */}
              </Routes>
            </Suspense>
          </AnimatePresence>
        </main>
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
